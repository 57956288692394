<template>
  <div>
    <v-row justify="space-between" align="center" class="mb-4">
      <v-col>
        <h3>Creatives</h3>
      </v-col>
      <v-col cols="auto"><update-banner-states /></v-col>
    </v-row>

    <creatives-table
      :headers="headers"
      :data="dsp_creatives"
      @deleteCreative="setDeleteCreative($event)"
      @submitBanner="setSubmitBanner($event)"
      @pauseBanner="pauseBanner($event)"
      @reSubmitBanner="setResubmitBanner($event)"
      @triggerPageChange="setCurrentPage($event)"
      @filterParams="setFilterParams($event)"
      @submitMultipleBanner="submitMultipleBanner($event)"
      @pauseMultipleBanners="pauseMultipleBanners($event)"
      :loading="loading"
      :customLoading="customLoading"
    />
  </div>
</template>

<script>
import CreativesTable from "../../../components/Tables/CreativesTable.vue"
import UpdateBannerStates from "../../../components/Btns/BtnUpdateBannerStates.vue"

export default {
  data: () => ({
    filterParams: {
      channel: "programmatic",
      search: "",
      page: 1,
      per_page: 10,
      campaign_id: null,
    },
    loading: true,
    customLoading: {},
    campaigns: [],
    advertisers: [],
    pagination: {
      page: 1,
      rowsPerPage: 10,
      totalItems: 0,
    },
    headers: [
      {
        text: "ID",
        align: "start",
        value: "banner_id",
      },
      {
        text: "Name",
        align: "start",
        value: "name",
        width: "240px",
      },
      { text: "Status", value: "banner_status" },
      { text: "Campaign", value: "campaign", width: "240px" },
      { text: "Objective", value: "banner_objective", width: "110px" },
      { text: "Type", value: "banner_type", width: "100px" },
      { text: "Click Effect", value: "banner_click_effect", width: "120px" },
      {
        text: "Submitted",
        value: "status",
        width: "120px",
      },
      {
        text: "Approval",
        value: "approval",
      },
      {
        text: "",
        value: "submit_status",
      },
      {
        text: "Actions",
        value: "action",
        align: "left",
        sortable: false,
      },
    ],
  }),
  components: {
    CreativesTable,
    UpdateBannerStates,
  },
  created() {
    this.getPaginatedCreatives()
  },
  async mounted() {
    //Advertisers
    // try {
    //   this.$store.commit("makingApiRequest", true);
    //   await this.$store.dispatch("dsp/getAllAdvertisers");
    //   this.$store.commit("makingApiRequest", false);
    // } catch (error) {
    //   this.error = error;
    //   this.$store.commit("makingApiRequest", false);
    // }
    //Agencies
    // try {
    //   this.$store.commit("makingApiRequest", true);
    //   this.$store.dispatch("dsp/getAgencies");
    //   this.$store.commit("makingApiRequest", false);
    // } catch (error) {
    //   this.error = error;
    //   this.$store.commit("makingApiRequest", false);
    // }
  },
  computed: {
    dsp_creatives() {
      return this.$store.state.dsp.dsp_creatives
    },
  },
  methods: {
    //set filter params
    setFilterParams(params) {
      this.filterParams = params
      this.filterParams.channel = "programmatic"
      this.filterParams.campaign_id = params?.campaign_id

      this.getPaginatedCreatives()
    },

    //Submit multiple banners
    async submitMultipleBanner(ids) {
      this.customLoading['submitMultipleBanners'] = true

      try {
        let body = {
          banner_ids: ids,
        }

        await this.$store.dispatch("dsp/submitManyBanners", body)
      } catch (error) {
        this.$notify({
          title:
            "Sorry, there was a problem submitting the banners. Please try again later",
          group: "errors",
        })
      } finally {
        this.customLoading['submitMultipleBanners'] = false
      }
    },

    async pauseMultipleBanners(ids) {
      this.customLoading['pauseMultipleBanners'] = true

      try {
        let body = {
          banner_ids: ids,
        }

        await this.$store.dispatch("dsp/pauseManyBanners", body)
      } catch (error) {
        this.$notify({
          title: "Sorry, something went wrong. Please try again later",
          group: "errors",
        })
      } finally {
        this.customLoading['pauseMultipleBanners'] = false
      }
    },
    //Delete banner
    async setDeleteCreative(id) {
      try {
        this.$store.commit("makingApiRequest", true)
        await this.$store.dispatch("dsp/deleteCreative", id)
        this.$store.commit("makingApiRequest", false)

        this.$notify({
          title: "Creative deleted successfully!",
          group: "success",
        })

        this.getPaginatedCreatives()
      } catch (error) {
        this.$notify({
          title:
            "Sorry, there was a problem deleting the banner. Please try again later",
          group: "errors",
        })
        this.$store.commit("makingApiRequest", false)
      }
    },

    //Submit banner
    async setSubmitBanner(id) {
      try {
        this.$store.commit("makingApiRequest", true)
        await this.$store.dispatch("dsp/submitBanner", id)
        this.$store.commit("makingApiRequest", false)

        this.$notify({
          title: "Creative submitted successfully!",
          group: "success",
        })

        this.getPaginatedCreatives()
      } catch (error) {
        this.$notify({
          title:
            "Sorry, there was a problem submitting the banner. Please try again later",
          group: "errors",
        })
        this.$store.commit("makingApiRequest", false)
      }
    },

    //Submit banner
    async pauseBanner(id) {
      try {
        this.$store.commit("makingApiRequest", true)
        await this.$store.dispatch("dsp/pauseBanner", id)
        this.$store.commit("makingApiRequest", false)

        this.$notify({
          title: "Banner updated successfully!",
          group: "success",
        })

        this.getPaginatedCreatives()
      } catch (error) {
        this.$notify({
          title: "Sorry, something went wrong. Please try again later",
          group: "errors",
        })
        this.$store.commit("makingApiRequest", false)
      }
    },

    //Resubmit Banner
    async setResubmitBanner(id) {
      try {
        this.$store.commit("makingApiRequest", true)
        await this.$store.dispatch("dsp/resubmitBanner", id)
        this.$store.commit("makingApiRequest", false)

        this.$notify({
          title: "Creative resubmitted successfully!",
          group: "success",
        })

        this.getPaginatedCreatives()
      } catch (error) {
        this.$notify({
          title:
            "Sorry, there was a problem resubmitting the creative. Please try again later",
          group: "errors",
        })
        this.$store.commit("makingApiRequest", false)
      }
    },

    async getPaginatedCreatives() {
      let body = {
        page: this.filterParams.page,
        search: this.filterParams.search,
        channel: this.filterParams.channel,
        campaign_id: this.filterParams.campaign_id,
        params: {
          per_page: this.filterParams.per_page,
        },
      }

      try {
        this.loading = true
        // this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("dsp/getPaginatedCreatives", body)
        // this.$store.commit("makingApiRequest", false);
        this.loading = false
      } catch (error) {
        // this.loading = false;
        this.error = error
        // this.$store.commit("makingApiRequest", false);
      } finally {
        this.loading = false
      }
    },

    //Set Pagination
    setCurrentPage(page) {
      this.pagination.page = page
    },
  },
}
</script>
